import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import type { Tag } from '@/core/tags/models/Tag';
import { differenceBy as _differenceBy } from 'lodash';

export const filteredUniqueMediaZones = (campaignMedia: CampaignMedia[] | undefined) => {
  const uniqueZones: Tag[] = [];
  campaignMedia?.forEach((media) => {
    const differentZones = _differenceBy(media?.zones, uniqueZones, 'id');
    uniqueZones.push(...differentZones);
  });
  return uniqueZones;
};
