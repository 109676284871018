import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import { StatisticStoreMutations } from '@/ui/stores/StatisticStore';
import { CampaignStoreMutations, CampaignStoreActions } from '@/ui/stores/CampaignStore';
import { Route } from 'vue-router';
import store from '@/ui/store';

export const nextRouteIsNotDetailsView = (nextRoute: Route) => {
  if (
    nextRoute.name !== ROUTE_NAMES.CAMPAIGN_DETAIL_STATISTICS &&
    nextRoute.name !== ROUTE_NAMES.CAMPAIGN_DETAIL_MEDIA
  ) {
    clearCampaignAndDetailStoreValues();
  }
};

function clearCampaignAndDetailStoreValues() {
  store.commit(CampaignStoreMutations.RESET_CAMPAIGN_DETAIL);
  store.commit(CampaignStoreMutations.RESET_CAMPAIGN_STATISTICS_DETAIL);
  store.commit(StatisticStoreMutations.RESET_CAMPAIGN_STATISTIC);
  store.dispatch(CampaignStoreActions.TRIGGER_SET_SELECTED_CAMPAIGN, undefined);
}
