











































import { Component, Mixins, Watch } from 'vue-property-decorator';
import CampaignCard from '@/ui/components/molecules/campaigns/CampaignCard.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import CampaignStatisticsFilter from '@/ui/components/organisms/filter/CampaignStatisticsFilter.vue';
import StatisticCard from '@/ui/components/molecules/statistics/StatisticCard.vue';
import StatisticCharts from '@/ui/components/molecules/statistics/StatisticCharts.vue';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { CampaignStoreGetters, CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { getFirstDayOfMonth, getLastDayOfMonth } from '@/ui/util/date';
import { StatisticStoreActions, StatisticStoreMutations } from '@/ui/stores/StatisticStore';
import { playoutFormat } from '@/ui/util/numberFormat';
import { BaseStatistic } from '@/core/statistics/models/Statistics';
import { STATISTIC } from '@/ui/constant/statistic.const';
import { Loader } from '@/ui/util/decorators/loader';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { filteredUniqueMediaZones } from '@/ui/util/filteredUniqueMediaZones';
import { nextRouteIsNotDetailsView } from '@/ui/util/clearCampaignDetails';

@Component({
  name: 'CampaignDetailStatistics',
  components: { CampaignStatisticsFilter, CampaignCard, StatisticCard, StatisticCharts },
})
export default class CampaignDetailStatistics extends Mixins(NavigationMixin) {
  private filteredZones: string[] | undefined;
  private statisticView = STATISTIC.CAMPAIGN;

  async created() {
    if (!this.campaignStatistic) {
      this.fetchCampaignStatistic();
    }
  }

  beforeDestroy() {
    nextRouteIsNotDetailsView(this.$route);
  }

  @Watch('selectedStatisticsDetailDates')
  onDatesChange(val: string[] | undefined, oldVal: string[] | undefined) {
    if (val && val !== oldVal) {
      this.$store.commit(StatisticStoreMutations.RESET_CAMPAIGN_STATISTIC);
      this.fetchCampaignStatistic();
    }
  }

  get checkIfPreviousStartDateIsAvailable() {
    return this.selectedStatisticsDetailDates && this.selectedStatisticsDetailDates[0]
      ? this.selectedStatisticsDetailDates[0]
      : this.campaignStartDate
      ? this.campaignStartDate
      : new Date().toISOString();
  }

  get checkIfPreviousEndDateIsAvailable() {
    return this.selectedStatisticsDetailDates && this.selectedStatisticsDetailDates[1]
      ? this.selectedStatisticsDetailDates[1]
      : new Date().toISOString();
  }

  get selectedStatisticsDetailDates() {
    return this.$store.state.campaignStore.selectedStatisticsDetailDates;
  }

  get selectedZones() {
    return this.$store.state.campaignStore.selectedStatisticsDetailZones;
  }

  get campaignDetailStatisticsZone() {
    return filteredUniqueMediaZones(this.campaignMedia);
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get selectedCampaign(): Campaign | undefined {
    return this.$store.getters[CampaignStoreGetters.GET_SELECTED_CAMPAIGN];
  }

  get campaignMedia(): CampaignMedia[] | undefined {
    return this.selectedCampaign?.media;
  }

  get campaignId() {
    return this.selectedCampaign?.id;
  }

  get campaignStartDate() {
    return this.selectedCampaign ? this.selectedCampaign.startDate : new Date().toISOString();
  }

  get campaignEndDate() {
    return this.selectedCampaign?.endDate;
  }

  get campaignStatistic(): BaseStatistic | undefined {
    return this.$store.state.statisticStore.campaignStatistic;
  }

  get playoutStatisticsPerMonth() {
    return this.campaignStatistic?.playoutsPerMonthByZone || [];
  }

  get playouts() {
    return playoutFormat(this.campaignStatistic?.playouts);
  }

  get mediaCount() {
    return playoutFormat(this.campaignStatistic?.mediaCount);
  }

  get mediaValue() {
    return playoutFormat(this.campaignStatistic?.mediaValue);
  }

  get zoneCount() {
    return playoutFormat(this.campaignStatistic?.zoneCount);
  }

  @Loader
  async fetchCampaignStatistic() {
    await this.$store.dispatch(StatisticStoreActions.SET_CAMPAIGN_STATISTIC, {
      user: this.currentUser,
      campaignId: this.campaignId,
      startDate: getFirstDayOfMonth(this.checkIfPreviousStartDateIsAvailable),
      endDate: getLastDayOfMonth(this.checkIfPreviousEndDateIsAvailable),
      zoneIds: this.filteredZones && this.filteredZones.length > 0 ? this.filteredZones : undefined,
    });
    this.hasStatistics();
  }

  onUpdateFilter(filteredZones: string[], filteredDates: string[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_STATISTICS_DETAIL_ZONES, filteredZones);
    this.$store.commit(CampaignStoreMutations.SET_FILTER_STATISTICS_DETAIL_DATES, filteredDates);

    this.filteredZones = filteredZones;
    this.fetchCampaignStatistic();
  }

  hasStatistics() {
    if (this.playoutStatisticsPerMonth?.length === 0) {
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: 'campaign.campaign-statistics.no-data-available',
        type: AlertType.WARNING,
      });
    }
  }
}
